import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { get } from 'lodash';
import { Button, Popover, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { DEFAULT_REFINEMENT } from "../../config"

import CustomSearchBox from "./SearchBox"
import CustomRangeSelectMin from "./RangeSelectMin"
import CustomRangeSelectMax from "./RangeSelectMax"
import CustomMenuSelect from "./MenuSelect"

import {
    bedroomsOptions,
    minPriceOptions,
    maxPriceOptions,
    buildingOptions
} from "../constants"

import { createSearchUrl, loadSearchParams } from '../../functions';

import {
    useAuthState
} from "@myaccountServices"
import clsx from "clsx"

import styles from "./style.css"

const RefineFilter = ({search_type, filterBtnProp, filterIconHeader, searchParams}) => {

    const { state: authState } = useAuthState();

    const [ filterParams, setFilterParams ] = useState(
        loadSearchParams(searchParams)
        /*{
        search_type: get(searchParams, 'search_type', ''),
        areas: get(searchParams, 'areas', ''),
        bedroom: get(searchParams, 'bedroom', '').toString(),
        building: get(searchParams, 'building', '').toString(),
        minPrice: get(searchParams, 'minPrice', '').toString(),
        maxPrice: get(searchParams, 'maxPrice', '').toString(),
        sortby: 'price',
        desc: true
    }*/);

    //
    // let refinementList = DEFAULT_REFINEMENT
    // if (search_type) {
    //     refinementList = [search_type]
    // }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const priceRangeEl = React.useRef(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const updateFilterParams = (field, value) => {
        setFilterParams({...filterParams, [field]: value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(createSearchUrl(filterParams, {
            useStatus: false,
            basePath: {
                residential: 'properties',
            }
        }))
    }

    // Filter open/close state for smaller devices
    const [filterClose, setFilterClose] = useState(false)
    const handleFilterOpen = (event) => {
        event.preventDefault()
        setFilterClose(!filterClose)
    }

    const filterCloseAlt = authState?.openSearchFilter || filterClose;

    return (
        <>
            {!filterIconHeader &&
                <Button
                    onClick={handleFilterOpen}
                    endIcon={filterCloseAlt ? <RemoveIcon /> : <AddIcon />}
                    classes={{
                        root: clsx("search-panel__btn", "search-panel__btn__label"),
                    }}
                    {...filterBtnProp}
                >
                    Filter
                </Button>
            }
            <div className={`search-panel__filters-wrap ${filterCloseAlt ? "" : " search-panel__filters__hidden"}`}>
                <div className={`search-panel__filters`}>
                    <CustomSearchBox
                        attribute="areas"
                        label="Search..."
                        value={filterParams.areas}
                        updateFilterParams={updateFilterParams}
                        InputProps={{
                            style: {height: '44px'}
                        }}
                    />
                    <CustomMenuSelect
                        attribute="building"
                        label="Property Type"
                        options={buildingOptions}
                        value={filterParams.building}
                        updateFilterParams={updateFilterParams}
                    />
                    <div className='price-range-filter' onClick={handleClick} ref={priceRangeEl}>
                        <label>Price Range</label>
                    </div>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        onClose={() => setAnchorEl(null)}
                        PaperProps={{
                            style: {width: priceRangeEl?.current?.offsetWidth ?? '100%'}
                        }}
                    >
                        <Box className='price-range-popover'>
                            <div className='min-price-container'>
                                <CustomRangeSelectMin
                                    attribute="minPrice"
                                    options={minPriceOptions[search_type] ?? []}
                                    value={filterParams.minPrice}
                                    updateFilterParams={updateFilterParams}
                                    variant="standard"
                                    fullWidth={true}
                                />
                            </div>
                            <div>
                                <CustomRangeSelectMax
                                    attribute="maxPrice"
                                    options={maxPriceOptions[search_type] ?? []}
                                    value={filterParams.maxPrice}
                                    updateFilterParams={updateFilterParams}
                                    variant="standard"
                                    fullWidth={true}
                                />
                            </div>
                        </Box>
                    </Popover>
                    <CustomRangeSelectMin
                        attribute="bedroom"
                        options={bedroomsOptions}
                        className="bedrooms-select"
                        value={filterParams.bedroom}
                        updateFilterParams={updateFilterParams}
                        InputProps={{
                            style: {height: '44px'}
                        }}
                    />
                    <Button className={`refine-submit`} variant="contained" onClick={(e) => handleSubmit(e)}>Search</Button>
                </div>
            </div>
        </>
    )
}

export default RefineFilter
