/**
// 1. Create a React component
const Stats = () => {
  // return the DOM output
};

// 2. Connect the component using the connector
const CustomStats = connectStats(Stats);

// 3. Use your connected widget
<CustomStats />
*/

// Ref: https://www.algolia.com/doc/api-reference/widgets/stats/react/#full-example

import React from "react"
import NoActions from "@myaccount/progress/NoActions";

const NoResults = props => {
    const { nbHits } = props

    if (nbHits === 0) {
        return <NoActions
            title={"Unfortunately, we do not currently have any properties that match your search criteria."}
            message={"Please refine your filters..."}
            icon="searchIcon"
        />
    } else {
        return null
    }
}

export default NoResults
