/* eslint-disable import/first */
import React, { useState, useEffect } from "react"
import { get } from 'lodash';
import { navigate } from 'gatsby'
// import qs from "qs"
import { useLocation } from "@reach/router"
import GlobalLayout from "@myaccount/GlobalLayout";
import {
    MYACCOUNT_SEARCH_RESULT_SALES,
    MYACCOUNT_SEARCH_RESULT_LETTINGS
} from "@myaccountUrls";

import { withSubtheme } from "@starberryComponentsMui"
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import { StarberryIcons } from '@myaccount/icons';
// import PropertyGrid from "../components-mui/PropertyGrid";
import SearchTitle from './hits/api/Title';
import {
    UserObjectStoreProvider,
    MyaccountStoreProvider,
    useUserObjectState,
        useMyaccountState,
    useAuthState
} from "@myaccountServices"

// Filter
import RefineFilter from './filter/api';
import CustomSortBy from "./filter/api/SortBy";
import LayoutSwitch from "./filter/layoutSwitch"
import SaveSearch from  '@myaccount/PropertyResult/SaveProperty';
import Map from  '@myaccount/map';
// import CustomInfiniteHits from "./hits/api/InfiniteHits"
import CustomStats from "./hits/api/Stats"
import NoResults from "./hits/api/Stats/NoResults"
import { /*searchClient, DEBOUNCE_TIME, */INDEX_NAME } from "./config"
// import range from 'lodash/range';
// const PAGE_SIZE = 12;

import {
    sortByCustomOptions
} from "./filter/constants"

import { /* createURL, searchStateToUrl, parseSearchUrl, */ urlToSearchState, propertyH1, loadSearchParams} from "./functions";
import { LinkItem } from '@myaccount/helper/myaccountLink';

import defaults from './defaults';
// import DetailedPropertyCardSkeleton
//     from "../../../../StarberryComponentsMui/components/Skeleton/DetailedPropertyCardSkeleton"

// const PropertyResultsListSkeleton = (props) => {
//     const { loading } = props

//     if (loading === true) {
//         return (
//             <Grid container spacing={4}>
//                 {range(0, 6).map(index => (
//                     <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
//                         <DetailedPropertyCardSkeleton />
//                     </Grid>
//                 ))}
//             </Grid>
//         )
//     }

//     return null;
// }


const ClearRefinements = ({ items, search_type }) => (
    <Button
        classes={{root: "search-clear-btn"}}
        variant="text" size="sm"
        disableRipple={false}
        onClick={() => {
            if (search_type === "lettings")
                navigate(MYACCOUNT_SEARCH_RESULT_LETTINGS)
            else
                navigate(MYACCOUNT_SEARCH_RESULT_SALES)
        }}

        // disabled={!items.length}
    >
        <CloseIcon color="inherit" fontSize="inherit" /> Clear filters
    </Button>
)
// const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

/*  Search Start */
// Ref - https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/
const AlgoliaSearch = React.memo(withSubtheme(props => {
    const {
        className,
        // loadMoreBtnProp,
        // loadPrevBtnProp,
        // loadMoreBtnLabel,
        // loadPrevBtnLabel,
        filterBtnProp,
        titleType,
        titleProps,
        titleStatsProps,
        gridViewProps,
        mapViewProps,
        propertyType,
        searchTypeLinkProps,
        showClearFilters,
        filterIconHeader,
        showSaveSearch,
    } = props

    const { state, services } = useMyaccountState();
    const [page /*, setPage*/] = React.useState(1);
    const hits = get(state, 'properties', []);
    const total = get(state, 'total', 0);
    // const loading = state?.loading || false;

    useEffect(() => {
        const strapiFieldMapping = (obj) => {
            // Ref:https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/filtering-locale-publication.html#filtering
            let fieldMapping = {
                areas: 'area',
                minPrice: 'min_price',
                maxPrice: 'max_price',
                building: 'property_type',
                bedroom: 'min_bedroom'
            }
            let newObj = {}
            Object.keys(obj).map((key) => {
                if (fieldMapping[key]) {
                    newObj[fieldMapping[key]] = obj[key];
                } else {
                    newObj[key] = obj[key];
                }
            })
            return newObj;
        }
        services.getCustomProperties(strapiFieldMapping(loadSearchParams({...searchParams, page:page, pagination: false}, false)))
    }, [page])

    const search_type = propertyType
    // get location
    const location = useLocation()
    const [searchState/*, setSearchState*/] = useState(
        urlToSearchState(window.location, search_type, '', true)
    )

    // const [debouncedSetState, setDebouncedSetState] = useState(null)

    // const onSearchStateChange = React.useCallback(searchState => {
    //     clearTimeout(debouncedSetState)
    //     setDebouncedSetState(
    //         setTimeout(() => {
    //             /**
    //              TODO: pagination retain prev page data working when using following
    //              1. window.history.pushState Ref: https://developer.mozilla.org/en-US/docs/Web/API/History/pushState#syntax
    //              2. react history.pushState
    //              Ref for above :https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react/#basic-urls
    //
    //              Since gatsby use navigate (instead of history, window) from @reach/router, here we need to use navigate but not working when using gatsby navigate
    //              Ref: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#replace-history-during-programmatic-navigation
    //              */
    //             // Finally using window.history.pushState
    //             window.history.pushState({pageID: search_type, searchState:searchState, pathname:searchStateToUrl(location, searchState)}, search_type, searchStateToUrl(location, searchState));
    //             // const urlTo = searchStateToUrl(location, searchState);
    //             // if (!urlTo.match(/page\-/)) {
    //             //     navigate(
    //             //         urlTo,
    //             //         // {
    //             //         //     state: searchState,
    //             //         //     replace: true
    //             //         // }
    //             //         searchState
    //             //     )
    //             // }
    //
    //         }, DEBOUNCE_TIME)
    //     )
    //     setSearchState(searchState)
    // }, [location, searchState, search_type]);

    const searchParams = searchState;
    const h1 = propertyH1(searchParams);

    return (
            <div className={className}>
                <form id="refineForm">
                <div className="search-topbar">
                    {showClearFilters &&
                        <div className="search-clear">
                            <ClearRefinements search_type={search_type} items={hits} />
                        </div>
                    }
                    {searchTypeLinkProps?.show &&
                        <div className={clsx("search-switch", searchTypeLinkProps?.showAsToggle && "search-switch-toggle")}>
                            <LinkItem to={MYACCOUNT_SEARCH_RESULT_SALES} className={search_type === 'sales' ? `Mui-selected` : ''}>Sales</LinkItem>
                            <LinkItem to={MYACCOUNT_SEARCH_RESULT_LETTINGS} className={search_type === 'lettings' ? `Mui-selected` : ''}>Lettings</LinkItem>
                        </div>
                    }
                </div>
                <RefineFilter search_type={search_type} filterBtnProp={filterBtnProp} filterIconHeader={filterIconHeader} searchParams={searchParams}/>
                <div className="search-panel__info">
                    <SearchTitle loading={state?.loading} title={h1} titleProps={titleProps} titleType={titleType} customStats={<CustomStats showOnlyNumber={true} nbHits={total} />}>
                        {("showResultsNumber" !== titleType) &&  <CustomStats titleStatsProps={titleStatsProps} />}
                    </SearchTitle>
                    <div className="search-panel__info__links">
                        <div className="sortyby-select">
                            <CustomSortBy
                                defaultRefinement={INDEX_NAME}
                                items={sortByCustomOptions}
                                searchParams={searchParams}
                            />
                        </div>
                        {showSaveSearch && (
                            <div>
                                <SaveSearch type="search" searchParams={searchParams} />
                            </div>
                        )}
                        <LayoutSwitch search_type={search_type} gridViewProps={gridViewProps} mapViewProps={mapViewProps} location={location} searchState={searchState} />
                    </div>
                </div>
                </form>
                <div className="algolia_results">
                    <NoResults titleStatsProps={titleStatsProps} />
                    <Map
                      zoom={10}
                      markers={hits}
                      center={{
                        lat: 0,
                        lng: 0
                      }}
                      // searchFilter={searchFilter}
                      // searchValues={searchValues}
                      // mapObj={mapObj}
                      // setMapObj={setMapObj}
                    />
                    {/*}<CustomInfiniteHits
                        loadMoreBtnProp={loadMoreBtnProp}
                        loadPrevBtnProp={loadPrevBtnProp}
                        loadMoreBtnLabel={loadMoreBtnLabel}
                        loadPrevBtnLabel={loadPrevBtnLabel}
                        hits={hits}
                        loading={loading && page === 1 && true}
                        onNext={() => { setPage(page + 1)}}
                        hasNext={!loading && (page - 1) * PAGE_SIZE < total && hits.length > 0}
                    />
                    <PropertyResultsListSkeleton loading={state?.loading} />*/}
                </div>
            </div>
    )
}, "propertyResultsPageFilters", defaults))

/* Search End */

const PropertyResultWrapper = ({search_type, filterIconHeader}) => {
    const { services } = useUserObjectState()
    const { services: authServices } = useAuthState()
    const isAuthenticated = authServices.isAuthenticated()

    const [filterOpen, setFilterOpen] = React.useState(false);
    const handleChange = (val) => {
        setFilterOpen(!val);
    };

    const pageRightDrawer = filterIconHeader ? <IconButton onClick={() => handleChange(filterOpen)}><StarberryIcons iconName="filterResults" /></IconButton> : null;
    React.useEffect(() => {
        if (isAuthenticated) {
            // on page load get the users saved objects
            services.getUserobject({ state:true })
        }
        authServices.updateState({pageTitle:`Search Results`, pageRightCustomComponent:pageRightDrawer, pageRightDrawer: null, pageLeftIcon: false});
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // Open filter component
    React.useEffect(() => {
        authServices.updateState({pageTitle:`Search Results`, pageRightCustomComponent:pageRightDrawer, openSearchFilter:filterOpen, pageRightDrawer: null, pageLeftIcon: false});
    }, [filterOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container maxWidth="xl">
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <AlgoliaSearch propertyType={search_type} filterIconHeader={filterIconHeader} />
                </Grid>
            </Grid>
        </Container>
    )
}

const SearchResultPageThemeWrap = withSubtheme(props => {
    const {
        search_type,
        filterIconHeader,
    } = props

    return (
        <MyaccountStoreProvider>
            <UserObjectStoreProvider>
                <PropertyResultWrapper search_type={search_type} filterIconHeader={filterIconHeader} />
            </UserObjectStoreProvider>
        </MyaccountStoreProvider>
    )
}, "propertyResultsPage")

const SearchResultPage = (props) => {
    return (
        <GlobalLayout>
            <SearchResultPageThemeWrap {...props} />
        </GlobalLayout>
    )
}

export default React.memo(SearchResultPage)
