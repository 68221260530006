import React, {useState} from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { DEFAULT_REFINEMENT } from "../config"

import CustomSearchBox from "./SearchBox"
import CustomRangeSelectMin from "./RangeSelectMin"
import CustomRangeSelectMax from "./RangeSelectMax"
import CustomMenuSelect from "./MenuSelect"
import CustomRefinementCheckbox from "./RefinementCheckbox"

import {
    RefinementList,
} from "react-instantsearch-dom"

import {
    bedroomsOptions,
    minPriceOptions,
    maxPriceOptions,
    buildingOptions
} from "./constants"

import {
    useAuthState
} from "@myaccountServices"
import clsx from "clsx"


const RefineFilter = ({search_type, filterBtnProp, filterIconHeader}) => {

    const { state: authState } = useAuthState();

    let refinementList = DEFAULT_REFINEMENT
    if (search_type) {
        refinementList = [search_type]
    }

    // Filter open/close state for smaller devices
    const [filterClose, setFilterClose] = useState(false)
    const handleFilterOpen = (event) => {
        event.preventDefault()
        setFilterClose(!filterClose)
    }

    const filterCloseAlt = authState?.openSearchFilter || filterClose;

    return (
        <>
            {!filterIconHeader &&
                <Button
                    onClick={handleFilterOpen}
                    endIcon={filterCloseAlt ? <RemoveIcon /> : <AddIcon />}
                    classes={{
                        root: clsx("search-panel__btn", "search-panel__btn__label"),
                    }}
                    {...filterBtnProp}
                >
                    Filter
                </Button>
            }
            <div className={`search-panel__filters-wrap ${filterCloseAlt ? "" : " search-panel__filters__hidden"}`}>
                <div className={`search-panel__filters`}>
                    <div style={{display: "none"}}>
                        <RefinementList
                            attribute="search_type"
                            defaultRefinement={refinementList}
                        />
                    </div>
                    <CustomSearchBox label="Search..." />
                    {/*<CustomMenuSelect
                        attribute="building"
                        label="Property Type"
                        options={buildingOptions}
                    />*/}
                    <CustomRangeSelectMin
                        attribute="price"
                        options={minPriceOptions[search_type] ?? []}
                    />
                    <CustomRangeSelectMax
                        attribute="price"
                        options={maxPriceOptions[search_type] ?? []}
                    />
                    <CustomRangeSelectMin
                        attribute="bedroom"
                        options={bedroomsOptions}
                        className="bedrooms-select"
                    />

                </div>
                <CustomRefinementCheckbox
                    attribute="building"
                    // options={bedroomsOptions}
                    searchable
                />
            </div>
        </>
    )
}

export default RefineFilter
