import React from 'react';
import { LinkItem } from '@myaccount/helper/myaccountLink';
import IconButton from '@mui/material/IconButton';
import GridViewIcon from '@mui/icons-material/GridView';
import RoomIcon from '@mui/icons-material/Room';
// import { SEARCH_BASE_URL } from "../config"
import { searchStateToUrl } from "../functions";

const LayoutSwitch = (props) => {
    const {
        // search_type,
        gridViewProps,
        mapViewProps,
        location,
        searchState
    } = props
    const mapPath = searchStateToUrl(location, {...searchState, layout:"map/"})
    const gridPath = searchStateToUrl(location, {...searchState, layout:"default"})
    return(
        <>
            <div>
                <LinkItem to={gridPath} className={`Mui-selected`}><IconButton {...gridViewProps} aria-label="Grid view"><GridViewIcon /></IconButton></LinkItem>
            </div>
            {/*<div>
                <LinkItem to={mapPath}><IconButton {...mapViewProps} aria-label="Map view"><RoomIcon /></IconButton></LinkItem>
            </div>*/}
        </>
    )
}

export default React.memo(LayoutSwitch);
