import { INDEX_NAME } from "../config"

import savedSearchFields from "@myaccount/forms/Forms/createSavedSearch"

let temp

// let buildingOptions = []
// temp = savedSearchFields.filter(item => item.name === "building")
// if (temp[0] && temp[0].values) {
//     buildingOptions = temp[0].values
// }
const buildingOptions = [
    { value: "apartment", label: "Apartment" },
    { value: "house", label: "House" },
    { value: "bungalow", label: "Bungalow" },
    { value: "development plot", label: "Development Plot"},
    { value: "maisonette", label: "Maisonette"},
    { value: "land", label: "Land" }
]

const sortByOptions = [
    { value: INDEX_NAME, label: "Sort by" },
    { value: `${INDEX_NAME}`, label: "Price desc" },
    { value: `${INDEX_NAME}_price_asc`, label: "Price asc" },
    { value: `${INDEX_NAME}_bedroom_desc`, label: "Bedroom desc" },
    { value: `${INDEX_NAME}_bedroom_asc`, label: "Bedroom asc" },
]

const sortByCustomOptions = [
    { value: '', label: "Sort by" },
    { value: `price_desc`, label: "Price desc" },
    { value: `price_asc`, label: "Price asc" },
    { value: `bedroom_desc`, label: "Bedroom desc" },
    { value: `bedroom_asc`, label: "Bedroom asc" },
]

let bedroomsOptions = []
temp = savedSearchFields.filter(item => item.name === "bedroom")
if (temp[0] && temp[0].values) {
    bedroomsOptions = temp[0].values
}

let minPriceOptions = []
temp = savedSearchFields.filter(item => item.name === "minPrice")
if (temp[0] && temp[0].values) {
    minPriceOptions = temp[0].values
}

let maxPriceOptions = []
temp = savedSearchFields.filter(item => item.name === "maxPrice")
if (temp[0] && temp[0].values) {
    maxPriceOptions = temp[0].values
}

export {
    bedroomsOptions,
    buildingOptions,
    minPriceOptions,
    maxPriceOptions,
    sortByOptions,
    sortByCustomOptions,
}
